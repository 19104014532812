var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",style:(_vm.isheight ? 'padding-bottom:120px' : '')},[_c('div',{staticClass:"head"}),_c('div',{staticClass:"body"},[_c('div',{staticClass:"box",on:{"click":function($event){return _vm.golink('information')}}},[_vm._m(0)]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.golink('organization')}}},[_vm._m(1)]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.golink('meeting')}}},[_vm._m(2)]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.golink('picturelive')}}},[_vm._m(3)]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.golink('hosts')}}},[_vm._m(4)]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.golink('about')}}},[_vm._m(5)]),_vm._m(6)]),_c('div',{staticClass:"bg1"}),_c('div',{staticClass:"bg2"}),_c('div',{staticClass:"bg3"}),_c('div',{staticClass:"bg4"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("@/assets/image2/icon-1.png"),"alt":""}})]),_c('p',[_vm._v("大会信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("@/assets/image2/icon-2.png"),"alt":""}})]),_c('p',[_vm._v("组织机构")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("@/assets/image2/icon-3.png"),"alt":""}})]),_c('p',[_vm._v("会议日程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("@/assets/image2/icon-4.png"),"alt":""}})]),_c('p',[_vm._v("图片直播")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("@/assets/image2/icon-5.png"),"alt":""}})]),_c('p',[_vm._v("讲者主持")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("@/assets/image2/icon-6.png"),"alt":""}})]),_c('p',[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('div',{staticClass:"logobox"},[_c('img',{attrs:{"src":require("@/assets/image2/logo2.png"),"alt":""}})]),_c('div',{staticClass:"logobox"},[_c('img',{attrs:{"src":require("@/assets/image2/logo1.png"),"alt":""}})]),_c('div',{staticClass:"logobox"},[_c('img',{attrs:{"src":require("@/assets/image2/logo3.png"),"alt":""}})])])
}]

export { render, staticRenderFns }