<template>
  <div class="home" :style="isheight ? 'padding-bottom:120px' : ''">
    <div class="head"></div>
    <div class="body">
      <div class="box" @click="golink('information')">
        <div class="content">
          <div class="pic">
            <img src="@/assets/image2/icon-1.png" alt="" />
          </div>
          <p>大会信息</p>
        </div>
      </div>
      <div class="box" @click="golink('organization')">
        <div class="content">
          <div class="pic">
            <img src="@/assets/image2/icon-2.png" alt="" />
          </div>
          <p>组织机构</p>
        </div>
      </div>
      <div class="box" @click="golink('meeting')">
        <div class="content">
          <div class="pic">
            <img src="@/assets/image2/icon-3.png" alt="" />
          </div>
          <p>会议日程</p>
        </div>
      </div>
      <div class="box" @click="golink('picturelive')">
        <div class="content">
          <div class="pic">
            <img src="@/assets/image2/icon-4.png" alt="" />
          </div>
          <p>图片直播</p>
        </div>
      </div>
      <div class="box" @click="golink('hosts')">
        <div class="content">
          <div class="pic">
            <img src="@/assets/image2/icon-5.png" alt="" />
          </div>
          <p>讲者主持</p>
        </div>
      </div>
      <div class="box" @click="golink('about')">
        <div class="content">
          <div class="pic">
            <img src="@/assets/image2/icon-6.png" alt="" />
          </div>
          <p>联系我们</p>
        </div>
      </div>
      <div class="logo">
        <div class="logobox">
          <img src="@/assets/image2/logo2.png" alt="" />
        </div>
        <div class="logobox">
          <img src="@/assets/image2/logo1.png" alt="" />
        </div>
        <div class="logobox">
          <img src="@/assets/image2/logo3.png" alt="" />
        </div>
      </div>
    </div>

    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>
    <div class="bg4"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isheight: false,
    };
  },
  created() {
    if (document.documentElement.clientHeight < 700) {
      this.isheight = true;
    }
  },
  mounted() {},
  methods: {
    golink(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  background: url("../../assets/image2/background.png") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  padding-bottom: 60px;
  position: relative;
  .head {
    background: url("../../assets/image2/home-head.png") no-repeat;
    background-size: 100% 100%;
    height: 220px;
  }
  .logo {
    padding-top: 15px;
    display: flex;
    z-index: 999;
    width: 100%;
    flex-wrap: wrap;
    .logobox {
      flex: 1;
      height: 90px;
      border: 1px dashed #e1746d;
      border-radius: 10px;
      margin: 0 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .body {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    z-index: 100;
    padding: 15px;
    box-sizing: border-box;
    .box {
      flex-basis: 50%;
      padding: 5px;
      box-sizing: border-box;
      .content:hover {
        background-color: #5f1818;
      }
      .content {
        padding: 16px 0;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        background-color: #9a0f10;
        z-index: 999;
        .pic {
          margin: 0 auto;
          border-radius: 50%;
          width: 42px;
          height: 42px;
          background-color: #fff;
          text-align: center;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 50%;
            height: 50%;
          }
        }
        p {
          letter-spacing: 3px;
        }
      }
    }
  }
  .bg1 {
    position: absolute;
    background: url("../../assets/image2/BG-1.png") no-repeat;
    background-size: 100% 100%;
    bottom: 100px;
    right: 0;
    z-index: 9;
    width: 165px;
    height: 225px;
  }
  .bg2 {
    position: absolute;
    background: url("../../assets/image2/BG-2.png") no-repeat;
    background-size: 100% 100%;
    bottom: 72px;
    left: 0;
    z-index: 9;
    width: 180px;
    height: 260px;
  }
  .bg3 {
    position: absolute;
    background: url("../../assets/image2/BG-3.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 120px;
  }
  .bg4 {
    position: absolute;
    background: url("../../assets/image2/BG-4.png") no-repeat;
    background-size: 100% 100%;
    bottom: 38px;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 70px;
  }
}
</style>
